import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { breakpoints } from "../../commons/breakpoints";
import Title from './_shared/Title';
import Loader from "./_shared/Loader";
import axios from "axios";
import GoogleMapReact from 'google-map-react';
import Marker from './../dealer-locator/Marker';
import Error from './Error';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const OfferContainer = styled.div`
  margin-bottom: 32px;
  
  @media ${breakpoints.sm} {
    display: flex;
  }
`;

const ResultBox = styled.div`
  width: 100%;
  
  @media ${breakpoints.sm} {
    flex: 1 1 0%;
    width: 50%;
    min-width: 500px;
  }
`;

const HeadBox = styled.div`
  margin-bottom: 32px;
`;

const Paragraph = styled.p`
  font-family: 'ReadLight';
  font-size: 11px;
  line-height: 16px;
  
  @media ${breakpoints.sm} {
    font-size: 16px;
    line-height: 23px;
  }
`;

const CarImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 16px;
`;

const InfoBox = styled.div`
  position: relative;
  padding: 23px 23px 23px 39px;
  border: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  background-color: ${props => props.theme.colors.darkWhite};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  
  @media ${breakpoints.sm} {
    margin: 16px 0;
  }
  
  ${Paragraph} {
    flex: 1 1 0%;
    margin: 0;
  }
`;

const Svg = styled.svg`
  width: 50px;
  max-width: 100%;
  margin-right: 16px;
`;

const CarTableContainer = styled.div`
  @media ${breakpoints.sm} {
    display: flex;
  }
`;

const CarTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-family: 'ReadLight';
  font-size: 11px;
  line-height: 16px;
  
  @media ${breakpoints.sm} {
    width: 50%; 
    font-size: 14px;
    line-height: 20px;
  }
`;

const CarTableBody = styled.tbody`
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px 0px;
`;

const CarTableRow = styled.tr`
  border-top: 1px solid ${props => props.theme.colors.darkWhite};
`;

const CarTableCell = styled.td`
  padding: 12px 16px;
  border-right: 1px solid ${props => props.theme.colors.darkWhite};
  
  &:nth-child(2) {
    width: 40%;
  }
  
  &.bold {
    font-family: 'ReadMedium';
  }
`;

const DealerSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 64px;
  font-family: 'ReadLight';
  
  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

const DealerInfo = styled.div`
  display: block;
  flex: 1 1 0%;
  padding: 32px;
  color: ${props => props.theme.colors.white};
  background-color:  ${props => props.theme.colors.brandColor};
`;

const DealerMap = styled.div`
  display: block;
  width: 100%;
  height: 500px;
  min-height: 350px;
  
  @media ${breakpoints.md} {
    width: 30%;
    height: auto;
  }
`;

const DealerInfoTitle = styled.p`
  margin: 0 0 32px 0;
  font-family: 'DaciaBlock';
  font-size: 24px;
  line-height: 34px;
`;

const DealerInfoName = styled.p`
  margin: 0;
  font-family: 'DaciaBlock';
  font-size: 16px;
  line-height: 23px;
`;

const DealerAddress = styled.div`
  margin: 32px 0;
`;

const DealerAddressLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const DealerAddressSvg = styled.svg`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: ${props => props.theme.colors.white};
`;

const DealerAddressLink = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`;

const BonusWrapper = styled.div`
  p:first-child {
    margin-bottom: 0;
    margin-top: 20px;
    
    @media ${breakpoints.md} {
      margin-top: 30px;
    }
  }
  ${Paragraph} {
    margin: 0;
    padding-left: 20px;
  }
`;

const Button = styled.a`
  display: inline-block;
  width: calc(100% - 32px);
  padding: 16px;
  color: ${props => props.theme.colors.white};
  font-size: 13px;
  line-height: 13px;
  font-family: 'ReadMedium';
  text-align: center;
  text-decoration: none;
  background-color: ${props => props.theme.colors.brandColor};
  border: 1px solid ${props => props.theme.colors.brandColor};
  vertical-align: middle;
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, border-color 0.5s ease 0s, color 0.5s ease 0s;
  cursor: pointer;
  
  @media ${breakpoints.sm} {
    width: 90%;
    font-size: 14px;
    line-height: 14px;
  }
  
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
    border-color: ${props => props.theme.colors.black};
  }
`;

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const FormStep8 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();
  const [quotation, setQuotation] = useState({});
  const [pricingId, setPricingId] = useState(null);
  const [error, setError] = useState(null);
  const selectedModel = data.carData.models.filter(el => el.Key.toString() === data.carData.model.toString());
  const selectedFuel = data.carData.fuels.filter(el => el.Key.toString() === data.carData.fuel.toString());
  const selectDealer = data.dealerData.dealers.Locations.filter(el => el.Id === data.dealerData.dealer);
  const dealerLat = selectDealer[0].Lat;
  const dealerLng = selectDealer[0].Lng;
  const carImageAlt = `${data.carData.brand.label} ${selectedModel[0].Value}`;
  const campaign = sessionStorage.getItem('tradeIn-campaign') || '';
  const origin = sessionStorage.getItem('tradeIn-origin') || '';
  let selectedMonth = data.carData.monthIndex;
  let newCar = (data.userData.buyCar === "new") ? "VN" : "VO";
  let interestCar = data.userData.newCar;
  let GACLIENTID = '';
  const GATRACKID = 'UA-23714986-1';
  const marketingOptinStatusEmail = data.userData.rEmail ? 'y' : 'n';
  const marketingOptinStatusTelephone = data.userData.rPhone ? 'y' : 'n';
  const marketingOptinStatusSms = data.userData.rSms ? 'y' : 'n';
  const marketingOptinStatus = `general: y, email: ${marketingOptinStatusEmail}, telephone: ${marketingOptinStatusTelephone}, sms: ${marketingOptinStatusSms}`;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');

  if (window.ga && window.ga !== undefined && typeof window.ga.getAll === 'function') {
    const tracker = window.ga.getAll()[0];
    const clientId = tracker.get('clientId');
    GACLIENTID = clientId;
  }

  if (data.carData.monthIndex < 10) {
    selectedMonth = '0' + data.carData.monthIndex;
  }

  if (data.userData.buyCar === "no") {
    newCar = null;
  }

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
    }
    return "desktop";
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('sendForm');
    setToken(token)
  }, [executeRecaptcha]);


  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);


  useEffect(() => {
    if (!token) return
    setData({
      type: 'UPDATE_LOADER',
      payload: {
        loader: true,
      },
    });

    axios.post(`${API_URL}add-tradeid-pricing`,
        {
          "EcCode": data.carData.car,
          "FirstRegistrationDate": `${data.carData.year}-${data.carData.monthIndex}`,
          "Mileage": data.carData.mileage,
          "ClientRequestDescription": "",
          "TypeOfInterest": newCar,
          "ModelOfInterest": interestCar,
          "DealerId": selectDealer[0].Bir,
          "Customer": {
            "CustomerType": "Customer",
            "CompanyName": "string",
            "ContactPerson": "string",
            "FirstName": data.userData.name,
            "LastName": data.userData.surname,
            "Email": data.userData.email,
            "Phone": data.userData.phone,
            "DataProcessing": true,
            "DataProcessingText": t('formCheckboxOWU'),
            "ContactByPhone": data.userData.rPhone,
            "ContactByPhoneText": t('formRPhoneTitle'),
            "ContactByEmail": data.userData.rEmail,
            "ContactByEmailText": t('formREmailTitle'),
            "ContactBySms": data.userData.rSms,
            "ContactBySmsText": t('formRSmsTitle')
          },
          "AdditionalInfo": {
            "GaClientID": GACLIENTID,
            "GaTrackID": GATRACKID,
            "GaUserID": null,
            "Campaign": campaign,
            "Device": deviceType(),
            "Origin": origin,
            "RecaptchaToken": token
          },
        },
        {
          headers: {
            'apiKey': `${API_KEY}`
          }
        })
        .then(function(response) {
          if (response.data.Success) {
            setQuotation(response.data);
            setPricingId(response.data.Data.PricingId);
            setError(null);

            let dataLayer = window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              pageName: "form/Wycena",
              pageType: "form",
              businessType: "used-car",
              formName: "ML-TI2S",
              formType: "trade_in_request",
              formCategory: "lead_usedcar",
              formStepName: "complete",
              formStepNumber: "8",
              leadId: response.data.Data.LeadId,
              marketingOptinStatus: marketingOptinStatus,
              event: "formValidate",
              eventCategory: "Leadform Validations",
              eventAction: "lead_vn",
              eventLabel: "tradein_manual",
              dealerName: selectDealer[0].Name,
              dealerId: selectDealer[0].Bir,
              vehicleModel: selectedModel[0].Value,
              vehicleId: selectedModel[0].Key
            });

          } else {
            setError(response.data.Errors[0]);
          }
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        })
        .catch(function(error) {
          console.log(error);
          setError(t('responseErrorDefault'));
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <>
        <Loader loaderClass={data.loader} opacity="1" />
        {error &&
          <Error text={error} />
        }
        {!error &&
          <>
            <OfferContainer>
              <ResultBox>
                <HeadBox>
                  <Paragraph>
                    {t('finalStepCarParagraph1')} {data.carData.brand.label} {selectedModel[0].Value}.
                  </Paragraph>
                </HeadBox>
                <div>
                  {!pricingId &&
                      <>
                        <Title title={t('finalStepFailTitle')}/>
                        <InfoBox>
                          <InfoContainer>
                            <Svg viewBox="0 0 44 42" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path d="M10.513 20.66h-1.01a1.518 1.518 0 0 1-1.513-1.524V14.05a1.52 1.52 0 0 1 1.514-1.525h1.01a1.52 1.52 0 0 1 1.513 1.526v5.086a1.52 1.52 0 0 1-1.514 1.525z" stroke="#000" strokeWidth="1.5"></path>
                                <path stroke="#000" d="M18.756 40l.672-3.068h5.383l.674 3.062zm-3.854-12.465l-.184-.095-.673 2.532 5.383 6.96 2.692-5.695c-2.83 0-5.385-1.498-7.218-3.702zm14.436 0c-1.833 2.204-4.388 3.702-7.218 3.702l2.692 5.695 5.382-6.96-.672-2.532-.184.095z"></path>
                                <path d="M41.144 34.685c-3.71-1.967-7.23-3.464-10.472-3.854h-.306l-4.404 6.102.673 3.384h-.252v.006h16.4v-2.898a3.1 3.1 0 0 0-1.64-2.74zm-27.27-3.855h-.307c-3.242.39-6.762 1.888-10.47 3.855a3.096 3.096 0 0 0-1.64 2.74v2.897h16.148l.672-3.39-4.403-6.1z" stroke="#000" strokeWidth="1.5"></path>
                                <path stroke="#002F8A" strokeWidth="3" d="M18.756 40.322h.252z"></path>
                                <path d="M33.727 20.66h1.01c.835 0 1.513-.682 1.513-1.524V14.05a1.52 1.52 0 0 0-1.514-1.525h-1.01a1.52 1.52 0 0 0-1.513 1.526v5.086a1.52 1.52 0 0 0 1.514 1.525zm.503-8.135C34.23 3.627 28.006 1 22.12 1c-5.886 0-12.112 2.627-12.112 11.525" stroke="#000" strokeWidth="1.5"></path>
                                <path d="M12.027 13.88c0-5.615 4.52-10.168 10.093-10.168 5.574 0 10.093 4.553 10.093 10.17v4.745c0 5.99-4.52 12.204-10.093 12.204-5.574 0-10.093-6.212-10.093-12.203V13.88z" stroke="#000" strokeWidth="1.5"></path>
                                <path fill="#EA5B94" d="M27.165 23.235a.734.734 0 0 1-.39.466c-3.516 1.808-6.354 1.633-8.512-.52a.87.87 0 0 1-.242-.566.89.89 0 0 1 .228-.583.829.829 0 0 1 .572-.23.828.828 0 0 1 .585.224c1.686 1.586 3.897 1.674 6.632.264a.798.798 0 0 1 .614-.05.734.734 0 0 1 .47.4.69.69 0 0 1 .045.597z"></path>
                                <path d="M34.23 20.66c0 3-1.796 5.425-11.437 5.425" stroke="#000" strokeWidth="2"></path>
                              </g>
                            </Svg>
                            <Paragraph>{t('finalStepFailContent')}</Paragraph>
                          </InfoContainer>
                        </InfoBox>
                      </>
                  }
                  {pricingId &&
                      <>
                        <Title title={t('finalStepTitle')}/>
                        <InfoBox>
                          <InfoContainer>
                            <Svg viewBox="0 0 44 42" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path d="M10.513 20.66h-1.01a1.518 1.518 0 0 1-1.513-1.524V14.05a1.52 1.52 0 0 1 1.514-1.525h1.01a1.52 1.52 0 0 1 1.513 1.526v5.086a1.52 1.52 0 0 1-1.514 1.525z" stroke="#000" strokeWidth="1.5"></path>
                                <path stroke="#000" d="M18.756 40l.672-3.068h5.383l.674 3.062zm-3.854-12.465l-.184-.095-.673 2.532 5.383 6.96 2.692-5.695c-2.83 0-5.385-1.498-7.218-3.702zm14.436 0c-1.833 2.204-4.388 3.702-7.218 3.702l2.692 5.695 5.382-6.96-.672-2.532-.184.095z"></path>
                                <path d="M41.144 34.685c-3.71-1.967-7.23-3.464-10.472-3.854h-.306l-4.404 6.102.673 3.384h-.252v.006h16.4v-2.898a3.1 3.1 0 0 0-1.64-2.74zm-27.27-3.855h-.307c-3.242.39-6.762 1.888-10.47 3.855a3.096 3.096 0 0 0-1.64 2.74v2.897h16.148l.672-3.39-4.403-6.1z" stroke="#000" strokeWidth="1.5"></path>
                                <path stroke="#002F8A" strokeWidth="3" d="M18.756 40.322h.252z"></path>
                                <path d="M33.727 20.66h1.01c.835 0 1.513-.682 1.513-1.524V14.05a1.52 1.52 0 0 0-1.514-1.525h-1.01a1.52 1.52 0 0 0-1.513 1.526v5.086a1.52 1.52 0 0 0 1.514 1.525zm.503-8.135C34.23 3.627 28.006 1 22.12 1c-5.886 0-12.112 2.627-12.112 11.525" stroke="#000" strokeWidth="1.5"></path>
                                <path d="M12.027 13.88c0-5.615 4.52-10.168 10.093-10.168 5.574 0 10.093 4.553 10.093 10.17v4.745c0 5.99-4.52 12.204-10.093 12.204-5.574 0-10.093-6.212-10.093-12.203V13.88z" stroke="#000" strokeWidth="1.5"></path>
                                <path fill="#EA5B94" d="M27.165 23.235a.734.734 0 0 1-.39.466c-3.516 1.808-6.354 1.633-8.512-.52a.87.87 0 0 1-.242-.566.89.89 0 0 1 .228-.583.829.829 0 0 1 .572-.23.828.828 0 0 1 .585.224c1.686 1.586 3.897 1.674 6.632.264a.798.798 0 0 1 .614-.05.734.734 0 0 1 .47.4.69.69 0 0 1 .045.597z"></path>
                                <path d="M34.23 20.66c0 3-1.796 5.425-11.437 5.425" stroke="#000" strokeWidth="2"></path>
                              </g>
                            </Svg>
                            <Paragraph>{t('finalStepCarParagraph2')}</Paragraph>
                          </InfoContainer>
                        </InfoBox>
                      </>
                  }
                </div>
                {quotation.Success && quotation.Data.BonusInfo &&
                  <BonusWrapper>
                    <Title title={t('finalStepBonusTitle')} />
                    <Paragraph dangerouslySetInnerHTML={ { __html :quotation.Data.BonusInfo } }></Paragraph>
                  </BonusWrapper>
                }
              </ResultBox>
              <div>
                {quotation.Success &&
                  <CarImage src={(quotation.Data.ImageUrl) ? quotation.Data.ImageUrl : process.env.PUBLIC_URL + 'default-car.png'} alt={carImageAlt}/>
                }
              </div>
            </OfferContainer>
            <Title title={t('finalStepCarSectionTitle')}/>
            <CarTableContainer>
              <CarTable>
                <CarTableBody>
                  <CarTableRow>
                    <CarTableCell>
                      {t('finalStepBrandLiteral')}
                    </CarTableCell>
                    <CarTableCell className="bold">
                      {data.carData.brand.label}
                    </CarTableCell>
                  </CarTableRow>
                  <CarTableRow>
                    <CarTableCell>
                      {t('finalStepModelLiteral')}
                    </CarTableCell>
                    <CarTableCell className="bold">
                      {selectedModel[0].Value}
                    </CarTableCell>
                  </CarTableRow>
                </CarTableBody>
              </CarTable>
              <CarTable>
                <CarTableBody>
                  <CarTableRow>
                    <CarTableCell>
                      {t('finalStepDriveLiteral')}
                    </CarTableCell>
                    <CarTableCell className="bold">
                      {selectedFuel[0].Value}
                    </CarTableCell>
                  </CarTableRow>
                  <CarTableRow>
                    <CarTableCell>
                      {t('finalStepRegistrationDateLiteral')}
                    </CarTableCell>
                    <CarTableCell className="bold">
                      {selectedMonth}/{data.carData.year}
                    </CarTableCell>
                  </CarTableRow>
                </CarTableBody>
              </CarTable>
            </CarTableContainer>
            <DealerSection>
              <DealerInfo>
                <DealerInfoTitle>
                  {t('finalStepDealerTitle')}
                </DealerInfoTitle>
                <DealerInfoName>
                  {selectDealer[0].Name}
                </DealerInfoName>
                <DealerAddress>
                  <DealerAddressLine>
                    <DealerAddressSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                      <path d="M14.334 0l.16.001.178-.001c.076 0 .151.005.156.01 2.244.09 4.21.797 5.868 2.11 2.208 1.678 3.31 4.214 3.31 7.544 0 3.335-3.052 8.872-9.151 16.759l-.531.686-.518-.696C7.936 18.53 5 12.993 5 9.663 5 6.335 6.102 3.799 8.3 2.13 9.842.906 11.64.209 13.675.04l.659-.04zm.019 1.321c-2.022.058-3.76.67-5.246 1.848-1.859 1.412-2.79 3.555-2.79 6.495 0 2.85 2.674 7.98 8.027 15.263 5.565-7.286 8.345-12.418 8.345-15.263 0-2.94-.93-5.083-2.8-6.503-1.446-1.146-3.144-1.757-5.182-1.842-.115-.002-.115-.002-.213-.001l-.141.003zm-.02 12.515c-2.395 0-4.337-1.898-4.337-4.238 0-2.34 1.942-4.238 4.338-4.238 2.395 0 4.337 1.897 4.337 4.238 0 2.34-1.942 4.238-4.337 4.238zm0-1.317c1.676 0 3.022-1.315 3.022-2.921 0-1.606-1.346-2.921-3.021-2.921-1.675 0-3.021 1.315-3.021 2.92 0 1.607 1.346 2.922 3.02 2.922zm-3.658 11.65l.238 1.296c-1.582.29-2.249.74-2.249 1.23 0 .558.64 1.134 2.077 1.65.678.22 1.862.338 3.524.338 1.657 0 2.81-.117 3.442-.339 1.541-.491 2.217-1.066 2.217-1.649 0-.523-.618-.932-2.134-1.14l.178-1.304c2.088.285 3.273 1.07 3.273 2.444 0 1.315-1.108 2.257-3.115 2.898-.79.278-2.065.407-3.86.407-1.79 0-3.091-.13-3.95-.409-1.921-.69-2.969-1.632-2.969-2.896 0-1.33 1.192-2.133 3.328-2.525z"></path>
                    </DealerAddressSvg>
                    <DealerAddressLink
                        href={`https://www.google.com/maps/place/${selectDealer[0].Street} ${selectDealer[0].PostalCode} ${selectDealer[0].City}`}
                        target="_blank">
                      {`${selectDealer[0].Street}, ${selectDealer[0].PostalCode} ${selectDealer[0].City}`}
                    </DealerAddressLink>
                  </DealerAddressLine>
                  <DealerAddressLine>
                    <DealerAddressSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12.784 17.469c0 .48-.24.792-.792.792-.528 0-.792-.312-.792-.792 0-.504.264-.792.792-.792.552 0 .792.288.792.792zM10.768 6.524h2.472V5.516h-2.472v1.008zM8.656 19.509h6.673c.48 0 .672-.264.672-.887V5.348c0-.624-.193-.888-.672-.888H8.656c-.48 0-.672.264-.672.888v13.274c0 .623.192.887.672.887zM7 5.204C7 4.052 7.551 3.5 8.704 3.5h6.576c1.153 0 1.705.552 1.705 1.704v13.585c0 1.152-.552 1.704-1.704 1.704H8.704C7.551 20.494 7 19.942 7 18.79V5.204z"></path>
                    </DealerAddressSvg>
                    <DealerAddressLink href={`tel:${selectDealer[0].PhoneNumber}`}>
                      {selectDealer[0].PhoneNumber}
                    </DealerAddressLink>
                  </DealerAddressLine>
                </DealerAddress>
              </DealerInfo>
              <DealerMap>
                <GoogleMapReact bootstrapURLKeys={{key: GOOGLE_MAPS_KEY}} defaultCenter={{lat: dealerLat, lng: dealerLng}} defaultZoom={14}>
                  <Marker key={1} lat={dealerLat} lng={dealerLng} number={''}/>
                </GoogleMapReact>
              </DealerMap>
            </DealerSection>
            <Title title={t('viewOffer')}/>
            <div style={{width: `240px`, marginBottom: `20px`}} >
              <Button href={'https://salon.dacia.pl/'} target="_blank">{t('textButton')}</Button>
            </div>
          </>
        }
      </>
  );
};

FormStep8.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep8;
